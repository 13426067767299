<template>
  <v-list-item-action>
    <slot />
  </v-list-item-action>
</template>

<script>
export default {
  props: {},
};
</script>
