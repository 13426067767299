<template>
  <v-expansion-panel-text>
    <slot />
  </v-expansion-panel-text>
</template>

<script>
export default {
  name: 'AExpansionPanelText',
};
</script>

<style scoped lang="scss"></style>
