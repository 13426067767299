<template>
  <a-container class="bg-background rounded mt-10">
    <a-row class="text-center">
      <a-col>Please install SurveyStack Kit from the Play Store to use with hardware. </a-col>
    </a-row>
  </a-container>
</template>

<script>
export default {};
</script>
